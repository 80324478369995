import PropTypes from 'prop-types';

IcoArrDown.defaultProps = {
    fill: "#FFFFFF",
};

IcoArrDown.propTypes = {
    fill: PropTypes.string,
}

function IcoArrDown(props){
    return(
        <svg {...props} width="10px" height="5px" viewBox="0 0 10 5">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-68.000000, -70.000000)" fill={props.fill}>
                    <polygon id="Shape" points="68 70 73 75 78 70"></polygon>
                </g>
            </g>
        </svg>
    );
}

export default IcoArrDown;