import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import { createChart, CrosshairMode } from 'lightweight-charts';

import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import Loader from "../../components/Loader";

import styles from './ChartBalance.module.css';

// const TIMEZONE_OFFSET = -(new Date().getTimezoneOffset()/60);
// const TIMEZONE_OFFSET = 0;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ChartBalance() {
    const query = useQuery();

    const [loading, setLoading] = useState(true);
    const chartElm = useRef(null);
    const chart = useRef(null);
    const areaSeries = useRef(null);

    const getVariables = useRef({
        strategy: "",
    });

    useEffect(()=>{
        
        setVariables();

        document.title = getVariables.current.strategy+" Balance";
        
        getData((balances)=>{  
            setLoading(false);
            setupChart(balances);
        });

        window.addEventListener('resize', onChartResize);
        
        return ()=>{
            //console.log("unmount");
            window.removeEventListener('resize', onChartResize);
        }
    }, []);

    function setVariables(){
        if(query.get("strategy") != null){
            getVariables.current.strategy = query.get("strategy");
        }
    }

    function getData(cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/chartBalance.php?strategy="+getVariables.current.strategy;
  
        Fetch.request(url, options, 9000)
            .then((response) => response.json())
            .then((json) => {  

                if(cb){
                    cb(json.balances_history);
                }

            })
            .catch((error) => {
                // console.log(error);
                if(cb){
                    cb([]);
                }
            });

    }

    function setupChart(balances){
        let chartOptions = {
            width: 1024,
            height: 768,
            layout: {
                backgroundColor: '#151924',
                textColor: 'rgba(255, 255, 255, 0.6)',
                fontSize: 12,
            },
            grid: {
                vertLines: {
                    color: '#242733',
                },
                horzLines: {
                    color: '#242733',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            rightPriceScale: {
                borderColor: '#323740',
            },
            timeScale: {
                borderColor: '#323740',
            },
        }
        chart.current = createChart(chartElm.current, chartOptions);
        chart.current.applyOptions({
            timeScale: {
                rightOffset: 12,
                barSpacing: 3,
                fixLeftEdge: true,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: false,
                borderColor: '#fff000',
                visible: true,
                timeVisible: true,
                secondsVisible: false,
            },
        });

        let balanceSeries = [];
        for(let i = 0; i < balances.length; i++){
            balanceSeries.push({
                time: balances[i].date_create, 
                value: parseInt(balances[i].amount)
            });
        }

        areaSeries.current = chart.current.addAreaSeries({
            topColor: 'rgba(39, 166, 154, 0.56)',
            bottomColor: 'rgba(76, 175, 80, 0.04)',
            lineColor: 'rgba(39, 166, 154, 1)',
            lineWidth: 2,
        });
        areaSeries.current.setData(balanceSeries);

        onChartResize();

    }

    function onChartResize(){
        if(chartElm.current == null){
            return;
        }
        let width = chartElm.current.offsetWidth;
        let height = chartElm.current.offsetHeight;
        if(chart.current){
            chart.current.resize(width, height);
        }
    }

    if(loading){
        return (<Loader />);
    }

    // let strategy = getVariables.current.strategy;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.chart} ref={chartElm}></div>
            </div>
        </div>
    );
}

export default ChartBalance;


