import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

import LogoutLink from '../../components/LogoutLink';

import styles from './Home.module.css';

function Home() {

    useEffect(()=>{
        return ()=>{
            //console.log("unmount");
        }
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.container}>

                <LogoutLink />

                <Link to="/mircea"><div className={styles.btn}>Mircea</div></Link>
                <Link to="/local"><div className={styles.btn}>Local</div></Link>
                <a href="https://trade.ptx.ro/?showTrades=1" target="_blank" rel="noreferrer"><div className={styles.btn}>Trade</div></a>

            </div>
        </div>
    );
}

export default Home;


