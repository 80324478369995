import PropTypes from 'prop-types';

IcoX.defaultProps = {
    fill: "#FFFFFF",
};

IcoX.propTypes = {
    fill: PropTypes.string,
}

function IcoX(props){
    return(
        <svg {...props} width="12px" height="12px" viewBox="0 0 12 12">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-359.000000, -297.000000)" fill={props.fill}>
                    <polygon id="Shape" points="371 298.2 369.8 297 365 301.8 360.2 297 359 298.2 363.8 303 359 307.8 360.2 309 365 304.2 369.8 309 371 307.8 366.2 303"></polygon>
                </g>
            </g>
        </svg>
    );
}

export default IcoX;