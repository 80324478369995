import React, {useEffect, useState} from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Loader from "../../components/Loader";
import ItemBalance from "../../components/ItemBalance";
import ItemTrade from "../../components/ItemTrade";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";
import TradeDialog from "../../components/TradeDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import LogoutLink from '../../components/LogoutLink';
import ToggleInput from "../../components/ToggleInput";

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import IcoRemove from '../../icons/IcoRemove';
import IcoDolar from '../../icons/IcoDolar';

import styles from './Local.module.css';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

function Local() {

    const [loading, setLoading] = useState(true);
    const [timeline, setTimeline] = useState("day");

    const [userBalances, setUserBalances] = useState([]);
    const [userTrades, setUserTrades] = useState([]);
    const [userBalanceHistory, setUserBalanceHistory] = useState([]);

    const [systemStatus, setSystemStatus] = useState("");

    const [exchangeInfo, setExchangeInfo] = useState({});

    const [selectedSymbol, setSelectedSymbol] = useState({
        symbol: "",
        orderId: "",
        coin1: "",
        coin2: "",
    });

    const [tradeDialogOpen, setTradeDialogOpen] = useState(false);
    const [snackbarTradeOpen, setSnackbarTradeOpen] = useState(false);
    const [statusTrade, setStatusTrade] = useState("");
    const [sideTrade, setSideTrade] = useState("buy");

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);

    const [confirmCancelOrderDialog, setConfirmCancelOrderDialog] = useState(false);

    const [confirmCancelAllDialog, setConfirmCancelAllDialog] = useState(false);

    const [masterSwitch, setMasterSwitch] = useState(false);


    useEffect(()=>{
        document.title = Constants.PAGE_NAME+" - Local";

        if(!localStorage.timeline) {
            localStorage.timeline = "day";
        }
        setTimeline(localStorage.timeline);

        getData();

        return ()=>{
            //console.log("unmount");
        }
    }, []);


    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/local.php?timeline="+localStorage.timeline;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                //master switch
                if(json.master_switch === "1"){
                    setMasterSwitch(true)
                }else{
                    setMasterSwitch(false)
                }

                //exchange info
                let exchange_info_obj = {};
                if(json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;
    
                        exchange_info_obj[symbol] = {};
                        exchange_info_obj[symbol].can_trade = false;
                        exchange_info_obj[symbol].coin1 = symbols[i].baseAsset;
                        exchange_info_obj[symbol].coin2 = symbols[i].quoteAsset;
    
                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                exchange_info_obj[symbol].can_trade = true;
                            }
                        }
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
    
                            if(filter.filterType === "PRICE_FILTER"){
                                exchange_info_obj[symbol].min_trade_price = parseFloat(filter.minPrice);
                            }
    
                            if(filter.filterType === "LOT_SIZE"){
                                exchange_info_obj[symbol].min_trade_quantity = parseFloat(filter.minQty);
                            }
    
                            if(filter.filterType === "MIN_NOTIONAL"){
                                exchange_info_obj[symbol].min_trade_value = parseFloat(filter.minNotional);
                            }
                        }
                    } 
                }
                setExchangeInfo(exchange_info_obj);
                
                //tickers
                var tickers = {};

                for(let i = 0; i < json.tickers.length; i++){
                    var ticker = json.tickers[i];
                    tickers[ticker.symbol] = parseFloat(ticker.price)
                }

                //balances
                var balances = {};

                for(let i = 0; i < json.balances.balances.length; i++){
                    var balance = json.balances.balances[i];
                    balances[balance.asset] = {
                        free: parseFloat(balance.free),
                        locked: parseFloat(balance.locked)
                    }
                }


                //user balances
                let userBalances = [];
                let coins = Object.keys(balances);

                for(let i = 0; i < coins.length; i++){
                    let coin = coins[i];

                    let symbol = coin+"USDT";
                    let balanceFree = 0;
                    let balanceLocked = 0;

                    let priceDecimals = 0;
                    let quantityDecimals = 0;

                    if(symbol === "USDTUSDT"){
                        balanceFree = balances[coin].free;
                        balanceLocked = balances[coin].locked;
                    }else if(tickers[symbol]){
                        balanceFree = tickers[symbol] * balances[coin].free;
                        balanceLocked = tickers[symbol] * balances[coin].locked;

                        if(exchange_info_obj[symbol]){
                            priceDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_price);
                            quantityDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_quantity);
                        }
                        
                    }else{
                        continue;
                    }

                    let balanceTotal = balanceFree+balanceLocked;

                    if(balanceTotal < 10){ //dolars
                        continue;
                    }

                    //for bnb
                    let symbol2 = coin+"BNB";
                    let balanceFree2 = 0;
                    let balanceLocked2 = 0;

                    if(symbol2 === "BNBBNB"){
                        balanceFree2 = balances[coin].free;
                        balanceLocked2 = balances[coin].locked;
                    }else if(tickers[symbol2]){
                        balanceFree2 = tickers[symbol2] * balances[coin].free;
                        balanceLocked2 = tickers[symbol2] * balances[coin].locked;
                    }

                    let balanceTotal2 = balanceFree2+balanceLocked2;

                    userBalances.push({
                        coin: coin,

                        free: balances[coin].free,
                        locked: balances[coin].locked,
                        total: balances[coin].free+balances[coin].locked,

                        free_usdt: balanceFree,
                        locked_usdt: balanceLocked,
                        total_usdt: balanceTotal,

                        free_bnb: balanceFree2,
                        locked_bnb: balanceLocked2,
                        total_bnb: balanceTotal2,

                        price_decimals: priceDecimals,
                        quantity_decimals: quantityDecimals
                    });
                }

                for(let i = 0; i < userBalances.length; i++){
                    let item = userBalances[i];
                    if(item.coin === "BNB"){
                        continue;
                    }

                    let symbol = item.coin+"BNB";

                    for(let j = 0; j < json.exchange_info.symbols.length; j++){
                        let item2 = json.exchange_info.symbols[j];

                        if(item2.symbol === symbol){
                            if(item2.status !== "TRADING"){
                                item.free_bnb = 0;
                                item.locked_bnb = 0;
                                item.total_bnb = 0;
                            }
                        }
                        
                    }
                }

                setUserBalances(userBalances);




                //user trades
                let userTrades = [];

                for(let i = 0; i < json.open_orders.length; i++){
                    let order = json.open_orders[i];

                    userTrades.push({
                        id: order.orderId.toString(),
                        time: order.time,
                        symbol: order.symbol,
                        type: order.type,
                        side: order.side,
                        price: parseFloat(order.price),
                        quantity: parseFloat(order.origQty),
                        filled_procent: parseInt((parseFloat(order.executedQty)/parseFloat(order.origQty))*100),
                        total_usdt: parseFloat(order.price)*parseFloat(order.origQty),

                        coin1: exchange_info_obj[order.symbol] ? exchange_info_obj[order.symbol].coin1 : "",
                        coin2: exchange_info_obj[order.symbol] ? exchange_info_obj[order.symbol].coin2 : "",
                    });
                }

                setUserTrades(userTrades);

                //user balance history
                let userBalanceHistory = [];

                for(let i = 0; i < json.balances_history.length; i++){
                    let balance = json.balances_history[i];
                    let balanceNext = null;
                    if(json.balances_history[i+1]){
                        balanceNext = json.balances_history[i+1];
                    }

                    let date_obj = new Date(parseInt(balance.date_create_ts)*1000);
                    let day = puDayOfWeek[date_obj.getDay()];
                    let date = balance.date_create;
                    let symbol = balance.symbol;
                    let amount = parseInt(balance.amount);
                    let revenue = 0;
                    if(balanceNext){
                        revenue = amount - parseInt(balanceNext.amount);
                    }

                    userBalanceHistory.push({
                        day: day,
                        date: date,
                        symbol: symbol,
                        amount: amount,
                        revenue: revenue,
                    });
                }

                setUserBalanceHistory(userBalanceHistory);

                setSystemStatus(json.system_status);

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function clickTimeline(value){
        localStorage.timeline = value;
        setTimeline(localStorage.timeline);
        getData();
    }

    function restartMirror(){
        let pass = prompt("System will be restarted. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/restartMirror.php?password="+pass;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }


    function stopMirror(){
        let pass = prompt("System will be shutdown. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/stopMirror.php?password="+pass;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }

    function sellCoin(coin, coin_dest, free, free_dest){
        if(coin === "USDT" || coin === "BNB"){
            return;
        }

        if(!exchangeInfo[coin+coin_dest].can_trade){
            alert("Cannot trade "+coin+coin_dest+" at this moment");
            return;
        }

        if(exchangeInfo[coin+coin_dest].min_trade_value > free_dest){
            alert("Cannot trade "+coin+coin_dest+": No minimum trade value");
            return;
        }

        let priceDecimals = Util.getDecimalsCount(exchangeInfo[coin+coin_dest].min_trade_price);
        let quantityDecimals = Util.getDecimalsCount(exchangeInfo[coin+coin_dest].min_trade_quantity);

        let amountSell = Util.toFixed(free, quantityDecimals);
        let forAmount = Util.toFixed(free_dest, priceDecimals);

        let pass = prompt("Sell "+coin+" "+amountSell+" = "+forAmount+" "+coin_dest+". Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/sellCoin.php?password="+pass+"&coin="+coin+"&coin_dest="+coin_dest+"&quantity="+amountSell;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });
    }

    function convertToBnb(coin, free, free_dest){
        sellCoin(coin, "BNB", free, free_dest)
    }

    function convertToUsdt(coin, free, free_dest){
        sellCoin(coin, "USDT", free, free_dest)
    }

    function onTradeStatus(res){
        setStatusTrade(res.status);
        setSnackbarTradeOpen(true);
    }

    function handleSnackbarTradeClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarTradeOpen(false);
    }

    function newBuy(){
        setSelectedSymbol({
            symbol: "BTCUSDT",
            coin1: "BTC",
            coin2: "USDT",
            orderId: "",
        });
        setTradeDialogOpen(true);
        setSideTrade("buy");
    }

    function newSell(){
        let coin1 = "";
        if(userBalances[0]){
            coin1 = userBalances[0].coin;
        }
        setSelectedSymbol({
            symbol: coin1+"USDT",
            coin1: coin1,
            coin2: "USDT",
            orderId: "",
        });
        setTradeDialogOpen(true);
        setSideTrade("sell");
    }

    const handleMenuClick = (event, obj) => {
        setAnchorElMenu(event.currentTarget);
        setSelectedSymbol(obj);
    };
    const handleMenuClose = () => {
        setAnchorElMenu(null);
    };

    function onMenuItemBuy(){
        handleMenuClose();
        setTimeout(()=>{
            setTradeDialogOpen(true);
            setSideTrade("buy");
        }, 300);
    }

    function onMenuItemSell(){
        handleMenuClose();
        setTimeout(()=>{
            setTradeDialogOpen(true);
            setSideTrade("sell");
        }, 300);
    }

    function onMenuItemCancel(){
        handleMenuClose();
        setTimeout(()=>{
            setConfirmCancelOrderDialog(true);
        }, 300);
    }

    function cancelOrder(){
        setConfirmCancelOrderDialog(false);
    
        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/tradeCancel.php?coin1="+selectedSymbol.coin1+"&coin2="+selectedSymbol.coin2+"&orderId="+selectedSymbol.orderId;
    
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    if(json.trade_result.status != null && json.trade_result.status === "CANCELED"){
                        getData();
                    }
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
        
    }

    function cancelAll(){
        setConfirmCancelAllDialog(false);

        let symbolsObj = {};
        for(let i = 0; i < userTrades.length; i++){
            symbolsObj[userTrades[i].symbol] = "1";
        }

        let symbols = "";
        let symbolsArr = Object.keys(symbolsObj);
        for(let i = 0; i < symbolsArr.length; i++){
            symbols += symbolsArr[i]+",";
        }

        if(symbols === ""){
            return;
        }

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/tradeCancelAll.php?symbols="+symbols;
    
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    getData();
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function onChangeMasterSwitch(value){

        let checked = 0;
        if(value){
            checked = 1;
        }

        let url = Constants.API_URL+"/api/masterSwitch.php?value="+checked;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setMasterSwitch(value);
                } 
            })
            .catch((error) => {
                alert("Error");
            });
    }

    let timelineDayCss = "";
    let timelineMonthCss = "";

    if(timeline === "day"){
        timelineDayCss = styles.active;
    }

    if(timeline === "month"){
        timelineMonthCss = styles.active;
    }

    let masterSwitchLabel = "NO";
    if(masterSwitch){
        masterSwitchLabel = "YES";
    }

    if(loading){
        return (<Loader />);
    }

    return (
        <div className={styles.root}>

            <div className={styles.mobile}>

                <LogoutLink />

                <div className={styles.title}>
                    Balances
                    <span className={styles.actionBtn+" "} onClick={newBuy} style={{marginLeft: "20px"}}>Buy</span> 
                    <span className={styles.actionBtn+" "} onClick={newSell}>Sell</span>
                </div>
                <div className={styles.balances}>
                    {userBalances.map((row, index) => {
                        return (
                            <ItemBalance 
                                key={row.coin}  
                                view_type="list" 
                                nr_crt={index+1} 
                                coin={row.coin} 
                                free={row.free} 
                                locked={row.locked} 
                                total={row.total} 
                                free_usdt={row.free_usdt} 
                                locked_usdt={row.locked_usdt} 
                                total_usdt={row.total_usdt}
                                free_bnb={row.free_bnb} 
                                locked_bnb={row.locked_bnb} 
                                total_bnb={row.total_bnb}
                                convertToBnb={convertToBnb}
                                convertToUsdt={convertToUsdt}
                                price_decimals={row.price_decimals}
                                quantity_decimals={row.quantity_decimals}
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>Enter new trades</div>

                <div style={{display: "inline-block", width:"100%", paddingLeft: "16px", paddingRight: "16px", marginTop: "20px"}}>
                    <div style={{float: "left", marginTop: "7px"}}>Mirror Trades: {masterSwitchLabel}</div>
                    <div style={{float: "right"}}>
                        <ToggleInput bgOff="#434C5A" bgOn="#EFBA0C" onChange={onChangeMasterSwitch} checked={masterSwitch} />
                    </div>
                </div>

                <div className={styles.title}>
                    Open orders
                    <span className={styles.actionBtn+" "} onClick={()=>{setConfirmCancelAllDialog(true)}} style={{marginLeft: "20px"}}>Cancel All</span> 
                </div>
                <div className={styles.trades}>
                    {userTrades.map((row, index) => {
                        return (
                            <ItemTrade 
                                key={row.id}  
                                view_type="list" 
                                nr_crt={index+1} 
                                id={row.id}
                                time={row.time}
                                symbol={row.symbol}
                                type={row.type}
                                side={row.side}
                                price={row.price}
                                quantity={row.quantity}
                                showMenu={true}
                                filled_procent={row.filled_procent}
                                total_usdt={row.total_usdt}>
                                    <div onClick={(e)=>{handleMenuClick(e, {symbol: row.symbol, orderId: row.id, coin1: row.coin1, coin2: row.coin2})}} className={styles.menu}></div>
                            </ItemTrade>
                        )
                    })}
                </div>

                <div className={styles.title}>
                    Balance history 
                    <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}} style={{marginLeft: "20px"}}>1D</span> 
                    <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>

                    <a className={styles.actionBtn+" "} style={{marginLeft: "10px"}} target="_blank" href={"/chart-balance?strategy=local"} rel="noreferrer">Chart</a> 
                </div>
                <div className={styles.balanceHistory}>
                    {userBalanceHistory.map((row, index) => {
                        return (
                            <ItemBalanceHistory 
                                key={index}  
                                view_type="list" 
                                nr_crt={index+1} 
                                day={row.day} 
                                date={row.date} 
                                symbol={row.symbol} 
                                amount={row.amount} 
                                revenue={row.revenue} 
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>
                    System is {systemStatus} <br/>
                    <span className={styles.actionBtn} onClick={restartMirror}>Restart Mirror</span> 
                    <span className={styles.actionBtn} onClick={stopMirror}>Stop Mirror</span> 
                </div>
            </div>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElMenu}
                open={openMenu}
                onClose={handleMenuClose}>

                <MenuItem onClick={onMenuItemBuy} disableRipple>
                    <IcoDolar fill="rgb(55, 65, 81)" style={{marginRight: 12, width:16}}/> 
                    {"Buy "+selectedSymbol.coin1+" with "+selectedSymbol.coin2}
                </MenuItem>  

                <MenuItem onClick={onMenuItemSell} disableRipple>
                    <IcoDolar fill="rgb(55, 65, 81)" style={{marginRight: 12, width:16}}/> 
                    {"Sell "+selectedSymbol.coin1+" for "+selectedSymbol.coin2}
                </MenuItem>

                <MenuItem onClick={onMenuItemCancel} disableRipple>
                    <IcoRemove style={{marginRight: 12}}/> 
                    Cancel Order
                </MenuItem>
            </StyledMenu>


            <TradeDialog 
                open={tradeDialogOpen} 
                side={sideTrade} 
                coin1={selectedSymbol.coin1}
                coin2={selectedSymbol.coin2}
                dynamic={true}
                onStatus={onTradeStatus} 
                onCancel={()=>{setTradeDialogOpen(false)}} 
                onOk={()=>{setTradeDialogOpen(false)}} 
            />  

            <Snackbar open={snackbarTradeOpen} autoHideDuration={6000} onClose={handleSnackbarTradeClose}>
                <Alert onClose={handleSnackbarTradeClose} severity={statusTrade} sx={{ width: '100%' }}>
                    <span style={{textTransform: "capitalize"}}>{sideTrade}</span> {statusTrade === "success" ? "completed successfully." : "failed."}
                </Alert>
            </Snackbar>

            <ConfirmDialog 
                open={confirmCancelOrderDialog} 
                title="Cancel Order" 
                text={"Cancel "+selectedSymbol.symbol+" order ?"} 
                okLabel="Yes" 
                cancelLabel="No" 
                onOk={cancelOrder} 
                onCancel={()=>{setConfirmCancelOrderDialog(false)}} /> 


            <ConfirmDialog 
                open={confirmCancelAllDialog} 
                title="Cancel All Orders" 
                text={"Cancel all orders ?"} 
                okLabel="Yes" 
                cancelLabel="No" 
                onOk={cancelAll} 
                onCancel={()=>{setConfirmCancelAllDialog(false)}} /> 
                        
        </div>
    );
}

export default Local;


