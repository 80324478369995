import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';

import styles from './ConfirmDialog.module.css';

ConfirmDialog.defaultProps = {
    open: false,
    title: "title",
    text: "text",
    okLabel: "Ok",
    cancelLabel: "Cancel",
    onOk: null,
    onCancel: null,
};

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,

    onOk: PropTypes.func,
    onCancel: PropTypes.func,
}

const ConfirmDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function ConfirmDialog(props) {
    function onOk(){
        if(props.onOk){
            props.onOk();
        }
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    return (

        <ConfirmDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>{props.title}</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    {props.text}
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>{props.cancelLabel}</div>
                    <div className={styles.btnOk} onClick={onOk}>{props.okLabel}</div>
                </div>
            </div>

        </ConfirmDialogStyled>

    );
}


export default ConfirmDialog;