import React, {useEffect, useState} from 'react';

import Loader from "../../components/Loader";
import ItemBalance from "../../components/ItemBalance";
import ItemTrade from "../../components/ItemTrade";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";
import LogoutLink from '../../components/LogoutLink';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Mircea.module.css';

const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Mircea() {

    const [loading, setLoading] = useState(true);
    const [timeline, setTimeline] = useState("day");

    const [userBalances, setUserBalances] = useState([]);
    const [userTrades, setUserTrades] = useState([]);
    const [userBalanceHistory, setUserBalanceHistory] = useState([]);

    const [exchangeInfo, setExchangeInfo] = useState({});

    useEffect(()=>{
        document.title = Constants.PAGE_NAME+" - Mircea";

        if(!localStorage.timeline_m) {
            localStorage.timeline_m = "day";
        }
        setTimeline(localStorage.timeline_m);

        getData();

        return ()=>{
            //console.log("unmount");
        }
    }, []);


    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/mircea.php?timeline="+localStorage.timeline_m;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   
                
                //exchange info
                let exchange_info_obj = {};
                if(json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;
    
                        exchange_info_obj[symbol] = {};
                        exchange_info_obj[symbol].can_trade = false;
    
                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                exchange_info_obj[symbol].can_trade = true;
                            }
                        }
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
    
                            if(filter.filterType === "PRICE_FILTER"){
                                exchange_info_obj[symbol].min_trade_price = parseFloat(filter.minPrice);
                            }
    
                            if(filter.filterType === "LOT_SIZE"){
                                exchange_info_obj[symbol].min_trade_quantity = parseFloat(filter.minQty);
                            }
    
                            if(filter.filterType === "MIN_NOTIONAL"){
                                exchange_info_obj[symbol].min_trade_value = parseFloat(filter.minNotional);
                            }
                        }
                    } 
                }
                setExchangeInfo(exchange_info_obj);
                
                //tickers
                var tickers = {};

                for(let i = 0; i < json.tickers.length; i++){
                    var ticker = json.tickers[i];
                    tickers[ticker.symbol] = parseFloat(ticker.price)
                }

                //balances
                var balances = {};

                for(let i = 0; i < json.balances.balances.length; i++){
                    var balance = json.balances.balances[i];
                    balances[balance.asset] = {
                        free: parseFloat(balance.free),
                        locked: parseFloat(balance.locked)
                    }
                }


                //user balances
                let userBalances = [];
                let coins = Object.keys(balances);

                for(let i = 0; i < coins.length; i++){
                    let coin = coins[i];
                    let symbol = coin+"USDT";
                    let balanceFree = 0;
                    let balanceLocked = 0;

                    let priceDecimals = 0;
                    let quantityDecimals = 0;

                    if(symbol === "USDTUSDT"){
                        balanceFree = balances[coin].free;
                        balanceLocked = balances[coin].locked;
                    }else if(tickers[symbol]){
                        balanceFree = tickers[symbol] * balances[coin].free;
                        balanceLocked = tickers[symbol] * balances[coin].locked;

                        if(exchange_info_obj[symbol]){
                            priceDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_price);
                            quantityDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_quantity);
                        }
                    }else{
                        continue;
                    }

                    let balanceTotal = balanceFree+balanceLocked;

                    if(balanceTotal < 10){ //dolars
                        continue;
                    }

                    userBalances.push({
                        coin: coin,

                        free: balances[coin].free,
                        locked: balances[coin].locked,
                        total: balances[coin].free+balances[coin].locked,

                        free_usdt: balanceFree,
                        locked_usdt: balanceLocked,
                        total_usdt: balanceTotal,

                        price_decimals: priceDecimals,
                        quantity_decimals: quantityDecimals
                    });
                }

                setUserBalances(userBalances);


                //user trades
                let userTrades = [];

                for(let i = 0; i < json.open_orders.length; i++){
                    let order = json.open_orders[i];

                    userTrades.push({
                        id: order.orderId.toString(),
                        time: order.time,
                        symbol: order.symbol,
                        type: order.type,
                        side: order.side,
                        price: parseFloat(order.price),
                        quantity: parseFloat(order.origQty),
                        filled_procent: parseInt((parseFloat(order.executedQty)/parseFloat(order.origQty))*100),
                        total_usdt: parseFloat(order.price)*parseFloat(order.origQty),
                    });
                }

                setUserTrades(userTrades);

                //user balance history
                let userBalanceHistory = [];

                for(let i = 0; i < json.balances_history.length; i++){
                    let balance = json.balances_history[i];
                    let balanceNext = null;
                    if(json.balances_history[i+1]){
                        balanceNext = json.balances_history[i+1];
                    }

                    let date_obj = new Date(parseInt(balance.date_create_ts)*1000);
                    let day = puDayOfWeek[date_obj.getDay()];
                    let date = balance.date_create;
                    let symbol = balance.symbol;
                    let amount = parseInt(balance.amount);
                    let revenue = 0;
                    if(balanceNext){
                        revenue = amount - parseInt(balanceNext.amount);
                    }

                    userBalanceHistory.push({
                        day: day,
                        date: date,
                        symbol: symbol,
                        amount: amount,
                        revenue: revenue,
                    });
                }

                setUserBalanceHistory(userBalanceHistory);

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function clickTimeline(value){
        localStorage.timeline_m = value;
        setTimeline(localStorage.timeline_m);
        getData();
    }

    let timelineDayCss = "";
    let timelineMonthCss = "";

    if(timeline === "day"){
        timelineDayCss = styles.active;
    }

    if(timeline === "month"){
        timelineMonthCss = styles.active;
    }

    if(loading){
        return (<Loader />);
    }

    return (
        <div className={styles.root}>

            <div className={styles.mobile}>

                <LogoutLink />

                <div className={styles.title}>Balances</div>
                <div className={styles.balances}>
                    {userBalances.map((row, index) => {
                        return (
                            <ItemBalance 
                                key={row.coin}  
                                view_type="list" 
                                nr_crt={index+1} 
                                coin={row.coin} 
                                free={row.free} 
                                locked={row.locked} 
                                total={row.total} 
                                free_usdt={row.free_usdt} 
                                locked_usdt={row.locked_usdt} 
                                total_usdt={row.total_usdt}
                                price_decimals={row.price_decimals}
                                quantity_decimals={row.quantity_decimals}
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>Open orders</div>
                <div className={styles.trades}>
                    {userTrades.map((row, index) => {
                        return (
                            <ItemTrade 
                                key={row.id}  
                                view_type="list" 
                                nr_crt={index+1} 
                                id={row.id}
                                time={row.time}
                                symbol={row.symbol}
                                type={row.type}
                                side={row.side}
                                price={row.price}
                                quantity={row.quantity}
                                filled_procent={row.filled_procent}
                                total_usdt={row.total_usdt}
                            />
                        )
                    })}
                </div>
            
                <div className={styles.title}>
                    Balance history 
                    <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}} style={{marginLeft: "20px"}}>1D</span> 
                    <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>

                    <a className={styles.actionBtn+" "} style={{marginLeft: "10px"}} target="_blank" href={"/chart-balance?strategy=mircea"} rel="noreferrer">Chart</a> 
                </div>
                <div className={styles.balanceHistory}>
                    {userBalanceHistory.map((row, index) => {
                        return (
                            <ItemBalanceHistory 
                                key={index}  
                                view_type="list" 
                                nr_crt={index+1} 
                                day={row.day} 
                                date={row.date} 
                                symbol={row.symbol} 
                                amount={row.amount} 
                                revenue={row.revenue} 
                            />
                        )
                    })}
                </div>
            </div>

            <div className={styles.desktop}>
                <table>
                    <caption>Balances</caption>
                    <thead>
                        <tr>
                            <th>Coin</th>

                            <th>Qty. Free</th>
                            <th>Qty. Locked</th>
                            <th>Qty. Total</th>

                            <th>$ Free</th>
                            <th>$ Locked</th>
                            <th>$ Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userBalances.map((row, index) => {
                            return (
                                <ItemBalance 
                                    key={row.coin}  
                                    view_type="table" 
                                    nr_crt={index+1} 
                                    coin={row.coin} 
                                    free={row.free} 
                                    locked={row.locked} 
                                    total={row.total} 
                                    free_usdt={row.free_usdt} 
                                    locked_usdt={row.locked_usdt} 
                                    total_usdt={row.total_usdt}
                                />
                            )
                        })}
                    </tbody>
                </table>

                <table>
                    <caption>Open trades</caption>
                    <thead>
                        <tr>
                            <th>Nr.</th>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Filled</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userTrades.map((row, index) => {
                            return (
                                <ItemTrade 
                                    key={row.id}  
                                    view_type="table" 
                                    nr_crt={index+1} 
                                    id={row.id}
                                    time={row.time}
                                    symbol={row.symbol}
                                    type={row.type}
                                    side={row.side}
                                    price={row.price}
                                    quantity={row.quantity}
                                    filled_procent={row.filled_procent}
                                    total_usdt={row.total_usdt}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            
        </div>
    );
}

export default Mircea;


