import PropTypes from 'prop-types';

IcoMenu.defaultProps = {
    fill: "#FFFFFF",
};

IcoMenu.propTypes = {
    fill: PropTypes.string,
}

function IcoMenu(props){
    return(
        <svg {...props} width="16px" height="4px" viewBox="0 0 16 4">
            <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 Z"></path>
        </svg>
    );
}

export default IcoMenu;